import React, { useEffect, createRef } from "react"
//Components
import Layout from "../components/layout"
// bootstrap ----------------------
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
// css ---------------------------
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// images -------------------------
import imgBannerRadial from './../../static/backgroundBanner.png'
// animation
import lottie from "lottie-web";
import GlobeLottie from "../resources/animations/55638-sustainability.json";

const Business = () => {
    const GlobeAnimation = createRef()

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: GlobeAnimation.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: GlobeLottie
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);


    const Radial = styled.section`
        min-height:100vh !important;
        background-image: url(${imgBannerRadial}); /* The image used */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        @media (max-width:992px){
            .containerForm{
                width:100% !important;
            }
        }
        @media (max-width:768px){
            .containerForm{
                width:85% !important;
            }
        }
        @media (max-width:480px){
            .containerForm{
                width:90% !important;
            }
        }
        h1{
            font-size:3rem;
		color:#293594;
		font-weight:bold;
        @media(max-width:992px){
           margin-top:0 !important;
        }
		@media (max-width: 768px){
			text-align:center !important;
		}
        }
        p{
            @media(max-width:992px){
            margin-bottom:40px !important;
        }
        }
    `;

    const ImageIntro = styled.img`
        margin-top:-50%;
        @media(max-width:1200px){
            margin-top:initial !important;
        }
        @media(max-width:992px){
            display:none!important;
        }
    `;
    const DiscoverInfo = styled.section`
		background: linear-gradient(180deg, rgba(169, 231, 234, 0.58) 8.54%, rgba(196, 196, 196, 0) 100%);
		padding-top:7rem;
		padding-bottom:10rem;
		@media (max-width:992px){
			padding-top:4rem;
			padding-bottom:5rem;
		}
		@media (max-width:420px){
			padding-bottom:2rem;
		}
        h2{
            font-family: Open Sans;
            font-weight: bold;
            font-size: 3rem;
            color: #293594;
        }
        @media(max-width:992px){
           h2,p{
                width:100% !important;
           }
        }
	`;

    const SectionEmision = styled.section`
        h2{
            font-family: Roboto;
            font-style: normal;
            font-weight: 900;
            font-size: 3rem;
            color: #293594;
        }
        p{
            font-family: Open Sans;
            font-size: 1.5rem;
            color: #000000;
        }
        @media (max-width: 768px){
			text-align:center !important;
		}
    `;
    const SectionConvert = styled.section`
        h2{
            font-family: Roboto;
            font-style: normal;
            font-weight: 900;
            font-size: 3rem;
            color: #293594;
        }
        p{
            font-family: Open Sans;
            font-size: 1.5rem;
            color: #000000;
        }
    `;
    const CardValores = styled.div`
        background: #F9F9F9;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        padding:3rem 1rem;
        min-height: 350px;
        margin-bottom:2rem;
        img{
            padding:2rem 0rem;
        }

        p.title{
            font-family: Roboto;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 35px;
            color: #000000;
            text-align:center;
        }
        p.body{
            font-family: Open Sans;
            font-size: 1.2rem;
            line-height: 35px;
            text-align:center;
            color: #000000;
        }
    `;

    return (
        <Layout>
            <Radial>
                <Container css={css`padding-top:10rem;height:100vh !important;`}>
                    <Row className="h-100">
                        <Col lg={6} md={12} >
                            <h1 className="mt-5">Aportando eficiencia energética para empresas</h1>
                            <p className="mt-3 text-center text-md-left" css={css`font-size:1.5rem;`}>
                                La nueva ley de Eficiencia energética está en Chile. ¿Qué significa eso? Se busca la neutralidad de carbono a nivel país para el año 2050.
							</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={12}></Col>
                        <Col lg={6} md={12} className="d-flex align-items-end" css={css`margin-top:-60%;`}>
                            <img className="w-100" src={'/persons.png'} />
                        </Col>
                    </Row>
                </Container>
            </Radial>
            <DiscoverInfo>
                <Container>
                    <h2 className="text-center w-75 d-flex justify-content-center m-auto  mb-4">Descubre como funciona el sistema y quienes son algunos de nuestros clientes</h2>
                    <hr className="w-50" css={css`height:0.5px; background-color:#000;`} />
                    <p className="text-center w-75 d-flex justify-content-center m-auto" css={css`font-size:1.5rem;`}>
                        Ofrecemos asesoría de gestión y coordinación de los proyectos eléctricos frente a las distribuidoras
                        con el objetivo de optimizar los tiempos de respuestas, como también, buscar y gestionar ahorros en los proyectos en cuestión.<br /><br />
                        Todas las empresas de cualquier rubro necesitan mejorar en términos de eficiencia energética, o como
                        nosotros nos gusta decirlo, hacer lo mismo, pero con menos.
                    </p>
                </Container>
            </DiscoverInfo>
            <SectionEmision>
                <Container>
                    <Row className="d-flex align-items-center">
                        <Col lg={6} md={12}>
                            <h2>
                                El 78% de las emisiones de nuestro país se produce en el sector de la Energía
                            </h2>
                            <p className="mt-4">Son 4 los vectores de cambio que <br />nuestra empresa se enfoca</p>
                        </Col>
                        <Col lg={6} md={12}>
                            <Row className="mt-5">
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <CardValores className="text-center">
                                        <img alt="linkedinIcon" className="img-fluid m-auto" css={css`max-width:100px;`} src={'/handIcon.svg'} />
                                        <p className="title">Sostenibilidad</p>
                                        {/* <p className="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
                                    </CardValores>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <CardValores className="text-center">
                                        <img alt="linkedinIcon" className="img-fluid m-auto" css={css`max-width:100px;`} src={'/iconCrew.svg'} />
                                        <p className="title">Edificios Inteligente, digitales e hiperconectados</p>
                                        {/* <p className="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
                                    </CardValores>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <CardValores className="text-center">
                                        <img alt="linkedinIcon" className="img-fluid m-auto" css={css`max-width:100px;`} src={'/bottleIcon.svg'} />
                                        <p className="title">Innovación procesos constructivos</p>
                                        {/* <p className="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
                                    </CardValores>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <CardValores className="text-center">
                                        <img alt="linkedinIcon" className="img-fluid m-auto" css={css`max-width:100px;`} src={'/iconEnergy.svg'} />
                                        <p className="title">Industrialización</p>
                                        {/* <p className="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
                                    </CardValores>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </SectionEmision>
            <SectionConvert>
                <Container className="pt-1 pt-lg-5">
                    <h2 className="text-center mt-0 mt-lg-5">
                        Conviértete en parte <br />
                        de la innovación
                    </h2>
                    <p className="text-center mt-3">Chile es potencia mundial en temas energéticos por la alta oferta de Energía renovable que nos <br />ofrece nuestro País.
                        Únete al cambio hoy y seamos todos los que salvemos al Planeta. </p>

                    <div className="animation-container" ref={GlobeAnimation} />
                </Container>
            </SectionConvert>
        </Layout>
    )
}

export default Business;
